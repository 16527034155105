/* REACT */
import { useState, useEffect } from "react"

/* LOCAL */
import { date2julian } from "../Calendar/utils"

export default function ContextORFEES({date, time}) {

   // GET DATA

   const [data, setData] = useState(null)
   const [loading, setLoading] = useState(true)
   const [error, setError] = useState(null)

   useEffect(() => {
      const j_date = date2julian(date)
      const query = {
         //   cors: "https://cors-anywhere.herokuapp.com/",
         url: "https://nenusun.obspm.fr/nancay/__system__/adql/query/form?",
         params: new URLSearchParams({
            __nevow_form__: "genForm",
            query:
               `select Thumbnail_url, `+
               `abs((time_min+time_max)/2.0 - ${time}) as time_diff `+
               `from orfees.epn_core ` + 
               `where time_min < '${j_date+1}' ` +
               `and time_max > '${j_date}' ` + 
               `and Target_name = 'Sun' ` +
               `order by time_diff`,
            _FORMAT: "JSON",
            submit: "Go",
            _TIMEOUT: 5,
            MAXREC: 1,
         }),
      }

      fetch(query.url + query.params.toString(), {
         method: "GET",
      })
         .then((response) => {
            return response.json()
         })
         .then((data) => {
            setData(data["data"][0])
            setLoading(false)
         })
         .catch((error) => {
            console.log(error)
            setLoading(false)
            setError(error)
         })
   }, [date])


    if (loading) {
        return <p>Loading</p>
    } else if (error) {
        return <p>Loading error</p>
    } else {
        return ((
            data ? 
               <div>
               <img width="100%" src={data[0]} alt={"ORFEES data for " + date} />
               <p>Observation Radiospectrale pour FEDOME et les Etudes des Eruptions Solaires<br/>
               Observatoire Radioastronomique de Nançay</p>
               </div>
            :
               <p>No data</p>
           )
        )
    }   

}