/* REACT */
import { Fragment } from "react"
import { DateTime } from "luxon"

// CALENDAR STUFF

/* Display a separator if weekDay is Sunday */
export function WeekSep({ weekDay, component: Component }) {
   return Number(weekDay) === 7 ? (
      <Component className="week-separator"></Component>
   ) : null
}

/* Skip days from Monday to first day */
export function SkipPrevMonth({ first_dayOfWeek }) {
   return [...Array(first_dayOfWeek)].map((d, i) => (
      <td className="prev-month" key={-i} />
   ))
}

/* Empty cells for remaining days of week */
export function SkipAfterMonth({ after_days, last_day }) {
   return [...Array(after_days)].map((d, i) => {
      const wday = last_day.plus({ days: i + 1 }).toFormat("c")

      return (
         <Fragment key={i}>
            <td className="next-month" key={i + 100}></td>
            <WeekSep weekDay={wday} component="td" key={50 + i} />
         </Fragment>
      )
   })
}

/* Julian Date to Epoch */
export function julian2epoch(j_date) {
   const jd = 2440587.5
   const e_date = DateTime.fromSeconds((j_date - jd) * 24 * 3600)
   return e_date
}

/* Epoch Date to Julian */
export function epoch2julian(e_date) {
   const jd = 2440587.5
   const j_date = (e_date  / 24.0 / 3600.0) + jd;
   return j_date
}

/*  Date to Julian */
export function date2julian(e_date) {
   const jd = 2440587.5
   const j_date = (DateTime.fromFormat(e_date, "yyyy-MM-dd").toSeconds() 
      / 24.0 / 3600.0) + jd;
   return j_date
}
