import { useRef, useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import { Link } from "react-router-dom"
import { DateTime, Settings } from "luxon"

/* MUI */
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Stack from "@mui/material/Stack"
import Slider from "@mui/material/Slider"
import Input from "@mui/material/Input"
import Typography from "@mui/material/Typography"

import Tab from "@mui/material/Tab"
import TabContext from "@mui/lab/TabContext"
import TabList from "@mui/lab/TabList"
import TabPanel from "@mui/lab/TabPanel"

/* HIGHCHARTS */

import Highcharts from "highcharts"
import HighchartsReact from "highcharts-react-official"

/* LOCAL */
import SumupVideo from "../Components/SumupVideo.js"
import Context from "../Components/Context.js"
import { julian2epoch } from "../Components/Calendar/utils"
import { epoch2julian } from "../Components/Calendar/utils"
// import myData from "../data/files.json"

Settings.defaultZone = "utc"
Settings.defaultLocale = "en"

function toImageTitle(times, value) {
   return (
      `${times[value][0].toLocaleString(DateTime.DATE_MED)} ` +
      ` // ${times[value][0].toLocaleString(DateTime.TIME_24_WITH_SECONDS)} ` +
      ` - ${times[value][1].toLocaleString(DateTime.TIME_24_WITH_SECONDS)}`
   )
}

function DisplayObsid({ sumup, images, times, movies, time_min, time_max }) {
   let { obsid, date } = useParams()

   const year = date.substring(0, 4)
   const nb_images = images ? images.size : 0
   const num_image_max = images ? nb_images - 1 : 0
   const time_middle = epoch2julian((time_min.ts + time_max.ts)/2000.0)

   const num_images_sumup = time_max
      ? Math.round(time_max.diff(time_min, "minutes").minutes)
      : 0
   const slider_marks = images
      ? [
           ...Array(nb_images)
              .fill(0)
              .map((val, idx) => {
                 return { value: idx, label: "" }
              }),
        ]
      : []

   useEffect(() => {
      //preloading images
      images.forEach((i) => {
         const img = new Image()
         img.src = i
      })
   }, [images])

   // SLIDER

   const sliderRef = useRef(null)
   const [value, setValue] = useState(0)
   const [filename, setFilename] = useState(images ? Array.from(images)[0] : "")
   const [imageTitle, setImageTitle] = useState(
      images ? toImageTitle(times, 0) : ""
   )

   const handleSliderChange = (event, newValue) => {
      setAllValues(newValue)
   }

   const handleInputChange = (event) => {
      let newValue = event.target.value === "" ? 0 : Number(event.target.value)
      if (newValue >= 0 && newValue <= num_image_max) {
         setAllValues(newValue)
      }
   }

   const handleBlur = () => {
      if (value < 0) {
         setValue(0)
      } else if (value > num_image_max) {
         setValue(num_image_max)
      }
   }

   function valuetext(value) {
      return `${value}`
   }

   // CHART

   function initOptions(x) {
      return {
         title: {
            text: obsid,
         },
         legend: {
            enabled: false,
         },
         credits: {
            position: {
               y: -2,
            },
         },
         xAxis: [
            {
               visible: false,
               crosshair: true,
               min: time_min.ts,
               max: time_max.ts,
               type: "datetime",
            },
            {
               visible: true,
               min: 0,
               max: num_images_sumup,
               title: {
                  text: "Frames",
               },
               plotLines: [
                  {
                     color: "#EE7A30",
                     width: 2,
                     value: x,
                  },
               ],
            },
         ],
         yAxis: {
            visible: false,
         },
         tooltip: {
            format: '<span style="font-size: 1.5em">{point.x:%H:%M:%S}</span>',
            shared: true,
         },
         accessibility: {
            enabled: false,
         },
         chart: {
            marginTop: 40,
            marginBottom: 45,
            marginLeft: 40,
            marginRight: 0,
            width: 700,
            height: 550,
            backgroundColor: "#00000000", // transparent
            events: {
               click: function (e) {
                  var value = Math.round(Number(e.xAxis[1].value))
                  setAllValues(value > num_image_max ? num_image_max : value)
               },
            },
         },
         series: [
            {
               // data: [...Array(nb_images).fill(0)],
               data: images
                  ? Array.from(images).map((im, idx) => {
                       return [times[idx][0].ts, 0]
                    })
                  : [],
               xaxis: 0,
               color: "#00000000", // transparent
            },
            {
               data: [...Array(nb_images).fill(0)],
               color: "#00000000", // transparent
               xaxis: 1,
            },
         ],
      }
   }

   const [options, setOptions] = useState(initOptions(0))

   // COMMON ACTIONS

   function setAllValues(value) {
      setValue(value)
      setFilename(Array.from(images)[value])
      setImageTitle(toImageTitle(times, value))
      setOptions(initOptions(value))
      sliderRef.current.focus()
   }

   // TABS

   const [tab, setTab] = useState("1")

   const handleTabChange = (event, newValue) => {
      setTab(newValue)
   }

   return (
      <Stack spacing={2} sx={{ mt: 2 }}>
         <Box sx={{ display: "flex", flexDirection: "row" }}>
            <Box>
               <Box
                  sx={{
                     backgroundImage: `url(${sumup})`,
                     backgroundRepeat: "no-repeat",
                     backgroundSize: "100%",
                     backgroundPositionY: "35px",
                     width: 700,
                     height: 550,
                     mr: "20px",
                  }}
               >
                  <HighchartsReact highcharts={Highcharts} options={options} />
               </Box>
               {/* 16px on the left to make the slider button under the 0 axis */}
               <Box sx={{ mr: "20px", ml: "40px" }}>
                  <Slider
                     value={value}
                     min={0}
                     max={num_images_sumup}
                     step={null}
                     marks={slider_marks}
                     getAriaValueText={valuetext}
                     valueLabelDisplay="off"
                     onChange={handleSliderChange}
                     aria-label="Time"
                  />

                  <Input
                     sx={{
                        width: 70,
                     }}
                     value={value}
                     size="small"
                     onChange={handleInputChange}
                     onBlur={handleBlur}
                     ref={sliderRef}
                     autoFocus={true}
                     inputProps={{
                        step: 1,
                        min: 0,
                        max: { num_image_max },
                        type: "number",
                        "aria-labelledby": "input-slider",
                     }}
                  />
               </Box>
            </Box>
            <Box>
               <TabContext value={tab}>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                     <TabList
                        onChange={handleTabChange}
                        aria-label="lab API tabs example"
                     >
                        <Tab label="Stokes" value="1" />
                        <Tab label="Context" value="2" />
                        {movies.map((file, idx) => {
                           const label =
                              movies.length > 1 ? `Video ${idx + 1}` : "Video"
                           const tab_value = (idx + 3).toString()
                           return (
                              <Tab
                                 label={label}
                                 value={tab_value}
                                 key={idx + 3}
                              />
                           )
                        })}
                     </TabList>
                  </Box>
                  <TabPanel value="1" key="1">
                     <Typography variant="h6">{imageTitle}</Typography>
                     <p>
                        <img
                           src={filename}
                           alt="Sum up"
                           title="Sum up"
                           width="100%"
                        />
                     </p>
                  </TabPanel>
                  <TabPanel value="2" key="2"  sx={{ padding: 0}}>
                     <Context date={date} time={time_middle} />
                  </TabPanel>
                  {movies.map((file, idx) => {
                     const tab_value = (idx + 3).toString()
                     return (
                        <TabPanel value={tab_value} key={idx}>
                           <SumupVideo date={"2024-02-08a"} file={file} />
                        </TabPanel>
                     )
                  })}
               </TabContext>
            </Box>
         </Box>
         <Box>
            <Link to={"/calendar/" + year}><Button variant="contained">Back to calendar</Button></Link>
         </Box>
      </Stack>
   )
}

export default function Obsid() {
   let { obsid } = useParams()
   const [loading, setLoading] = useState(true)
   const [error, setError] = useState(null)

   // GET DATA

   const [data, setData] = useState(null)

   useEffect(() => {
      const query = {
         //   cors: "https://cors-anywhere.herokuapp.com/",
         url: "https://nenusun.obspm.fr/nancay/__system__/adql/query/form?",
         params: new URLSearchParams({
            __nevow_form__: "genForm",
            query:
               `select Granule_gid, access_url, time_min, time_max ` +
               `from nenusun.epn_core where obs_id='${obsid}' order by time_min`,
            _FORMAT: "JSON",
            submit: "Go",
            _TIMEOUT: 5,
            MAXREC: 10000,
         }),
      }

      fetch(query.url + query.params.toString(), {
         method: "GET",
      })
         .then((response) => {
            return response.json()
         })
         .then((data) => {
            setData(data["data"])
            setLoading(false)
         })
         .catch((error) => {
            setLoading(false)
            setError(error)
            console.log(error)
         })
   }, [obsid])

   // SUMUP

   const sumup = data
      ? data
           .filter((d) => {
              return d[0] === "L1-preview"
           })
           .map((d) => d[1].replace("BST_Sum_Up.png", "BST.png"))[0]
      : null

   // TIME_MIN

   const [time_min, time_max] = data
      ? data
           .filter((d) => {
              return d[0] === "L1-preview"
           })
           .map((d) => [julian2epoch(d[2]), julian2epoch(d[3])])[0]
      : [null, null]

   // IMAGES PER MINUTE

   const images = data
      ? new Set(
           data
              .filter((d) => {
                 return d[0] === "L1-preview-stokes"
              })
              .map((d) => d[1])
        )
      : null

   // TIMESTAMPS OF IMAGES

   const times = data
      ? data
           .filter((d) => {
              return d[0] === "L1-preview-stokes"
           })
           .map((d) => [julian2epoch(d[2]), julian2epoch(d[3])])
      : null

   // MOVIES

   const movies = data
       ? data
             .filter((d) => {
                 return d[0] === "L2-preview-movie"
             })
             .map((d) => d[1])
       : null

   if (loading) {
      return <p>Loading</p>
   } else if (error) {
      return <p>{error}</p>
   } else {
      return (
         <DisplayObsid
            sumup={sumup}
            images={images}
            times={times}
            movies={movies}
            time_min={time_min}
            time_max={time_max}
         />
      )
   }
}
