/* REACT */
import { useState } from "react"

/* MUI */

import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"
import Tab from "@mui/material/Tab"
import TabContext from "@mui/lab/TabContext"
import TabList from "@mui/lab/TabList"
import TabPanel from "@mui/lab/TabPanel"

/* LOCAL */
import ContextNRH from "../Components/Context/ContextNRH.js"
import ContextNDA from "../Components/Context/ContextNDA.js"
import ContextORFEES from "../Components/Context/ContextORFEES.js"
// import ContextClimso from "../Components/Context/ContextClimso.js"
import ContextBASS2000 from "../Components/Context/ContextBASS2000.js"


export default function Context({date, time}) {
    
    const [tab, setTab] = useState("1")
    const handleTabChange = (event, newValue) => {
        setTab(newValue)
    }
    
    return(
        
        <TabContext value={tab}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                onChange={handleTabChange}
                aria-label="lab API tabs example"
                >
                    <Tab label="NRH" value="1" />
                    <Tab label="NDA" value="2" />
                    <Tab label="H alpha" value="3" />
                    <Tab label="CaH" value="4" />
                    <Tab label="ORFEES" value="5" />
                    <Tab label="Climso" value="6" />
                </TabList>
            </Box>
            <TabPanel value="1" key="1">
                <ContextNRH date={date} time={time}/>
            </TabPanel>
            
            <TabPanel value="2" key="2">
                <ContextNDA date={date} time={time}/>
            </TabPanel>
            
            <TabPanel value="3" key="3">
                <ContextBASS2000 date={date} time={time} instrument="Meudon Spectroheliograph" filter="H Alpha"/>
            </TabPanel>
            
            <TabPanel value="4" key="4">
                <ContextBASS2000 date={date} time={time} instrument="Meudon Spectroheliograph" filter="CaH"/>
            </TabPanel>
            
            <TabPanel value="5" key="5">
                <ContextORFEES date={date} time={time}/>
            </TabPanel>
            
            <TabPanel value="6" key="6">
                <ContextBASS2000 date={date} time={time} instrument="Climso Pic du Midi" filter="HeI"/>
            </TabPanel>
        
        </TabContext>
    )    
    
}